@import 'src/core/styles/mixins';

.game-is-over-form {
  // max-height: calc(100vh - 10rem);
  max-height: 512px;

  .form-content {
    width: 100%;
    height: 100%;

    &__section {
      overflow-y: auto;
      height: 100%;
      max-height: 100%;
      padding-right: 0.5rem;
    }
  }

  .record-line {
    padding: 0.5rem 0;
  }

  .score-info {
    @include flexBox(row, nowrap, space-between, center);
  }

  .big-text {
    font-size: 1.25rem;
    font-weight: bold;
  }

  .stats-list {
    list-style: none;
    width: 100%;

    &__item {
      margin-bottom: 0.5rem;
      padding: 0;

      @include flexBox(row, nowrap, space-between, center);

      &:last-of-type {
        margin-bottom: 0;
      }

      .tile-value {
        display: inline-block;
        padding: 0.5rem;
        border-radius: 4px;
        text-align: center;
        width: 5rem;
      }
    }
  }
}