@import 'src/core/styles/mixins';

.top-games-item {
  background-color: $blueGrey;
  border-radius: 4px;
  color: $darkGrey;
  cursor: pointer;
  font-size: 1.2rem;
  margin-top: 0.5rem;
  padding: 1rem;
  transition: background-color 300ms;
  user-select: none;
  width: 100%;

  @include flexBox(row, nowrap, space-between, center);

  &:first-of-type {
    margin-top: 0;
  }

  &:hover {
    background-color: $golden;
  }

  &__rank {
    display: inline-block;
    width: 2rem;
  }

  &__record {
    width: calc(100% - 2rem);

    @include flexBox(row, nowrap, space-between,center);

    .record-login {

    }

    .record-score {

    }

    .record-max {
      border-radius: 4px;
      color: $white;
      padding: 0.25rem;
      width: 5rem;

      @include flexBoxCentered();
    }
  }

}
