@import 'src/core/styles/mixins';
@import 'src/core/styles/media';

.top-games-form {
  height: calc(100% - 2rem);
  width: calc(100% - 2rem);

  @include media(desktop) {
    max-height: 512px;
    max-width: 512px;
  }

  &-fieldset {
    height: 100%;

    &__title {
      height: 1.5rem;
    }

    &__content {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;

      .top-list {
        width: 100%;
      }
    }
  }
}
